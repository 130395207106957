.app-pages--acoount .component-account{
    min-width: 320px;
}

.component-account--container {
    border: 1px solid #e0e5ea;
    padding: 15px 40px;
    border-radius: 15px;
    text-align: center;
}

.app-pages--acoount .section-panel--start h2, .app-pages--acoount .section-panel--start p{
    color: #e0e5ea
}

.app-pages--acoount .component-header{
    margin-bottom: 10px
}

.app-pages--acoount .component-header p{
    display: none;
}

.app-pages--acoount .component-account code{
    color: #e83e8c
}

.app-pages--acoount .component-account .button-primary{
    width: 35vmin;
    margin-right: 40px;
}

.app-pages--acoount .section-socialbutton {
    text-align: center;
}

.app-pages--acoount .social-button {
    width: 25vmin;
    height: 25vmin;
    margin-right: 10vmin;
    margin-top: 10vmin;
}

.app-pages--acoount .social-button:last-child {
    margin-right: 0!important;
}

.app-pages--acoount .social-button p {
    margin-bottom: 0!important;
}

.social-button--facebook {
    color: #3c5a99;
}

.social-button--facebook:hover {
    background-color: #3c5a99!important;
    border-color: #3c5a99!important;
}

.social-button--google {
    color: #DB4437;
}

.social-button--google:hover {
    background-color: #DB4437!important;
    border-color: #DB4437!important;
}

.social-button--microsoft {
    color: #7fba00;
}

.social-button--microsoft:hover {
    background-color: #7fba00!important;
    border-color: #7fba00!important;
}

.app-pages--acoount .component-account--container .button-group--flex{
    margin-top: 35px
}

.app-pages--acoount .component-account--container .app-logo {
    animation: unset!important;
}

.button-group--100 .btn {
    width: 100%;
    margin-bottom: 40px;
}

.component-account--container .app-footer {
    margin-bottom: 0!important
}

.toast-title {
    color: whitesmoke;
}