.component-navbar {
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0;
    z-index: 9999;
    background: #343a40;
}

.navbar {
    z-index: 9999;
}

.navbar-link--language .nav-link{
    color: #e0e5ea
}

.navbar-link--language span{ margin-right: 5px}

.component-navbar .navbar-brand img{
    height: 50px;
}

.component-navbar .navbar-dark .navbar-brand {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}

.component-navbar .navbar-brand span {
    vertical-align: middle;
    color: #e0e5ea;
}

.navbar-side{
    background: #00000061;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
}

.component-navbar .navbar-button--user:hover ~ .navbar-side{
    display: block!important;
}

.navbar-button--user {
    position: absolute;
    right: 0;
    top: 0;
    height: 65px;
    border-radius: 0;
    width: 65px;
    background: unset;
    border: 0;
    box-shadow:none!important
}

.navbar-button--user:hover, .navbar-button--user:active, .navbar-button--active{
    color: #e0e5ea!important;
    background-color: #585f66!important;
    border-color: #f8f9fa!important;
}

.navbar-nav.side-nav.open {
    right: 0;
}

.navbar-nav.side-nav {
    background: #585f66;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, .1);
    position: fixed;
    top: 65px;
    flex-direction: column!important;
    right:0px;
    width: 300px;
    overflow-y: auto;
    bottom: 0;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.animate{-webkit-transition:all .3s ease-in-out;-moz-transition:all .3s ease-in-out;-o-transition:all .3s ease-in-out;-ms-transition:all .3s ease-in-out;transition:all .3s ease-in-out}

.side-nav .list-group-item {
    background: transparent!important;
    border: 0!important;
}

.side-nav .list-group .section-account {
    color: #e0e5ea  !important;
    padding: .75rem 1.25rem;
    margin-top: 20px;
}

.side-nav .list-group .section-account h2 {
    font-size: calc(10px + 5vmin)!important;
}

.side-nav .list-group .section-account .btn {
    min-width: 100%!important;
}

.side-nav .listgroup-item--login {
    vertical-align: middle;
    border-bottom: 1px solid #ffffff2b !important;
    cursor: pointer;
}

.side-nav .listgroup-item--login:hover {
    background: #cccccc52 !important;
}

.side-nav .listgroup-item--login .media {
    align-items: center!important;
}

.side-nav .listgroup-item--login .listgroup-item--p {
    color: #e0e5ea;
}

.side-nav .listgroup-item--login .listgroup-item--media {
    font-size: 30px;
    color: #e0e5ea;
}

.component-navbar .navbar-link--language .dropdown-menu {
    right: 0;
    left: unset;
}