.section-panel--wct{
    padding-bottom: 0!important;
}

.section-whatcantrack {
    text-align: center;
}

.section-whatcantrack h2 {
    font-weight: 700;
    text-align: center;
    font-size: calc(18px + 5vmin);
}

.section-whatcantrack button {
    height: 110px;
    margin-top: 10vmin;
    font-size: calc(16px + 2vmin);
    line-height: 1.2;
    font-weight: 300;
    flex-grow: 1;
    margin-right: 17px;
}

.section-whatcantrack button:last-child {
    margin-right: 0!important
}

.section-whatcantrack button p{
    margin-bottom: 0
}

.app-pages--home .section-panel--track {
    background-color: #343a40 !important;
}

.whatcantrack-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.icon-container {
    margin-top: 16px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 12px;
    /* font-size: 32px; */
    color: grey;
}

.icon-container:hover {
    background-color: gray;
    color: white;
}

.sms:hover {
    background-color: #E5C76F;
    border: 1px solid #E5C76F;
    color: white;
}

.ussd:hover {
    background-color: #FF9800;
    border: 1px solid #FF9800;
    color: white;
}

.email:hover {
    background-color: #D44638;
    border: 1px solid #D44638;
    color: white;
}

.mensseger:hover {
    background-color: #006AFF;
    border: 1px solid #006AFF;
    color: white;
}

.viber:hover {
    background-color: #665CAC;
    border: 1px solid #665CAC;
    color: white;
}

.whatsapp:hover {
    background-color: #25D366;
    border: 1px solid #25D366;
    color: white;
}

.push-notification:hover {
    background-color: #F84F31;
    border: 1px solid #F84F31;
    color: white;
}

.da-sinal:hover {
    background-color: #20A464;
    border: 1px solid #20A464;
    color: white;
}

.min-height--70 {
    min-height: 70vh;
}

@media (min-width: 992px) {
    .app-pages--home .component-track{
        min-width: 728px;
    }
}

@media (max-width: 576px) {
    .section-whatcantrack button:nth-child(2n) {
        margin-right: 0!important
    }    
}