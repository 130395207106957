.section-account .btn {
    border-radius: 40px;
    margin: 4vmin 0;
    min-width: 50vmin;
}

.section-account h2 {
    font-weight: 700;
    text-align: center;
    font-size: calc(18px + 5vmin);
}

.section-account .text-size--small{
    font-size: 14px;
}

.section-account {
    text-align: center;
}

