
body {
  overflow-x: hidden;
}

.app-logo {
  height: 15vmin;
  pointer-events: none;
}

.component-header {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e0e5ea;
}

.app-footer {  
  font-size: 12px;
  color: #495057;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.app-link {
  color: #61dafb;
}

.text--capitalize {
  text-transform: uppercase;
}

.section-panel {
  padding: 20vmin 15vmin;
}

.section-panel--flex {
  background-color: #343a40 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button-group--flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control:focus {
  border-color: #0bb331!important;
  box-shadow: 0 0 0 .2rem rgba(94, 187, 115, 0.25)!important;
}

.social-links--instagram:hover {
  color: #E1306C;
}

.social-links:nth-child(2) {
  margin-left: 0!important;
}
.social-links {
  color: #28a745;
  font-size: 25px;
  margin-left: 20px;
}

.app-footer div p{
  margin-bottom: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 991px) {
  .navbar-brand {
      margin-right: 0!important;
  }

  .section-panel {
      padding: 20vmin 5vmin!important;
  }

  .navbar-nav.side-nav {
      width: 100%!important;
  }

  .section-panel--flex{
    height: auto!important;
  }
}
