.component-trackdetail {
    margin-top: 35px;
    padding-left: 35px;
}

.shop-tracking-status .order-status-timeline {
    height: 12px;
    border: 1px solid #aaa;
    border-radius: 7px;
    background: #eee;
    box-shadow: 0px 0px 5px 0px #C2C2C2 inset;
    width: 96%;
    top: 28%;
    z-index: 0;
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completion {
    height: 8px;
    margin: 1px;
    border-radius: 7px;
    background: #f2bc33;
    width: 0px
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completed {
    background: #b5e6ae!important;
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completion.c1 {
    width: 22%
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completion.c2 {
    width: 46%
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completion.c3 {
    width: 61%
}

.shop-tracking-status .order-status-timeline .order-status-timeline-completion.c4 {
    width: 100%
}

.content2 {
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    border-radius: 12px;
}

.component-trackdetail--completed .content2 {
    background-color: #b5e6ae!important;
}

.content2-header1 {
    text-align: center;
}

.content2-header1 {
    text-align: center;
    padding: 1em;
}

.content2-header1 p {
    font-size: 16px;
    font-weight: 700;
    color: #7B7B7B;
    margin: 0;
}

.component-trackdetail--completed .content2-header1 p {
    color: #4E7D48!important;
}

.content2-header1 span {
    font-size: 14px;
    font-weight: 400;
}

.clear {
	clear:both;
}

ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 35px
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 9px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline > li {
    margin: 20px 0;
    position: relative;
}

ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #28a745;
    left: -35px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

ul.timeline > li > p{
    color: #9D9D9D;
    text-align: justify;
    line-height: 1.2;
}

ul.timeline > li > span {
    font-weight: 700;
    color: #7b7b7b;
}

ul.timeline > .active:before {
    -webkit-animation: trackdetails-progress-active infinite 6s;
    animation-delay: 1s;
    animation: trackdetails-progress-active infinite 6s;
    -webkit-animation-delay: 1s;
    border: 3px solid #e2e2e2;
}
  
@-webkit-keyframes trackdetails-progress-active {
    0% {border-color: #e2e2e2;}
    50% {border-color: orange;}
    100% {border-color: #e2e2e2;}
}
  
@keyframes trackdetails-progress-active {
    0% {border-color: #e2e2e2;}
    50% {border-color: orange;}
    100% {border-color: #e2e2e2;}
}

.trackdetail-header {
    margin-bottom: 40px;
}

.trackdetail-header h1 {
    font-weight: 700;
    font-size: 32px;
}

.trackdetail-header h3 {
    color: #a8a8a8;
    font-weight: 300;
}

.trackdetail-h4{
    border-top: 1px solid #ddd;
    padding-top: 40px;
}

.float-button{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:20px;
	background-color:#28A745;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
	z-index:1000;
    animation: bot-to-top 2s ease-out;
    padding: 13px 0;
    font-size: 22px;
    cursor: pointer;
}

.float-button--ul{
	position:fixed;
	right:28px;
	padding-bottom:20px;
	bottom:80px;
	z-index:100;
}

.float-button--ul li{
	list-style:none;
	margin-bottom:10px;
}

.float-button--ul li span{
	background-color: #f6fff8;
    color: #418e4e;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
	width:60px;
	height:60px;
    display:block;
    cursor: pointer;
}

.float-button--ul:hover{
	visibility:visible!important;
	opacity:1!important;
}


.my-float{
	font-size:24px;
	margin-top:18px;
}

#menu-share + .float-button--ul{
  visibility: hidden;
}

#menu-share:hover + .float-button--ul{
  visibility: visible;
  animation: scale-in 0.5s;
}

.icon-border {
    border: 1px solid #848484;
    padding: 10px;
    border-radius: 100%;
    margin-bottom: 5px;
    display: inline-block;
    height: 50px;
    width: 50px;
    background: #FFF;
}

.color-green {
    color: #0bb331;
}

.color-gray {
    color: #000;
}

.color-red {
    color: #d50000;
}

.readmore-link {
    font-size: 16px;
    color: #ccc;
    margin-left: 10px;
    padding-top: 10px;
}

.readmore-link:hover{
    color: #28a745!important;
}

ul.timeline>li  .svg-inline--fa{
    position: absolute;
    left: -29px;
    top: 6px;
    z-index: 401;
    width: 8px;
    height: 8px;
}

@keyframes bot-to-top {
    0%   {bottom:-40px}
    50%  {bottom:40px}
}

@keyframes scale-in {
    from {transform: scale(0);opacity: 0;}
    to {transform: scale(1);opacity: 1;}
}

@keyframes rotate-in {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes rotate-out {
    from {transform: rotate(360deg);}
    to {transform: rotate(0deg);}
}

@media (max-width: 724px) {
    .navbar-brand {
        margin-right: 0!important;
    }
  
    .section-panel {
        padding: 20vmin 5vmin!important;
    }
  
    .navbar-nav.side-nav {
        width: 100%!important;
    }
  
    .float-button {
      right: 20px!important;
    }

    .float-button--ul {
        position: fixed;
        right: 25px!important;
    }

    .component-trackdetail {
       padding-left: 0!important;
    }
  }

@media (max-width: 576px){
    .content2-header1 {
        padding: 0.3em!important;
    }

    .content2 {
        flex-direction: column!important;
    }

    .color-green {
        max-width: 57px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    .color-gray {
        max-width: 57px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}