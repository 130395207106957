.track-form {
    display: flex;
    max-width: 728px;
    margin: auto;
}

.track-form--input{
    flex-direction: row!important;
}

.track-button--square{
    width: 110px;
    height: 110px;
    margin-bottom: 8px
}

.track-button--square:last-child{
    margin-bottom: 0!important
}

.track-form .btn{
    border-radius: 4px
}

.track-form .btn p{
    margin-bottom: 0!important
}

.track-form .btn-success {
    color: #ffffff;
    border-color: #0bb331;
}

.track-form .btn-success:hover {
    color: #fff;
    background-color: #0bb331;
    border-color: #0bb331;
    box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

.track-form .btn-success:not(:disabled):not(.disabled).active:focus, .track-form .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

.track-form .btn-success:not(:disabled):not(.disabled).active, .track-form .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0bb331;
    border-color: #0bb331;
}

.component-track .track-form .track-buttons {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.component-track input.track-textarea--lg {
    height: 40px;
}

.ReactCodeMirror {
    width: 100%;
  }
  
.CodeMirror {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace!important;
    border-radius: 4px!important;
    height: 227px!important;
    margin-right: 8px!important;
}

.CodeMirror-focused {
    border-color: #0bb331!important;
    box-shadow: 0 0 0 .2rem rgba(94, 187, 115, 0.25)!important;
}

.CodeMirror-empty {
    color: #9c9c9c!important;
}
  

@media (max-width: 576px) {
    .track-form {
        flex-direction: column;
    }

    .component-track .track-form .track-buttons {
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 15px;
    }

    .track-form .track-buttons .track-button--square {
        flex-grow: 1;
        margin-left: 15px;
    }

    .track-form .track-buttons .track-button--square:first-child {
        margin-left: 0!important;
    }

    .CodeMirror {
        margin-right: 0!important;
    }
}